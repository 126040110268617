import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function FreeDemo() {

  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className="section section-free-demo">
        <Container style={{zIndex: 25}}>
          <Row>
            <Col lg="12" md="12">
              <div className="section-description">
                <h2 className="title">Features & Pricing</h2>
                <h5 className="description" style={{color: '#111'}}>
                  See our features below.
                </h5>
                <div className="clearfix"></div>
              </div>
              <Row>
                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6",
                      zIndex: 999,
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h3">Scheduling</CardTitle>
                      <ul>
                        <li>
                          Drop In Play
                        </li>
                        <li>
                          Single Class Event
                        </li>
                        <li>
                          Special Events
                        </li>
                        <li>
                          Birthday Parties
                        </li>
                        <li>
                          Party Add Ons
                        </li>
                        <li>
                          Multiple Locations
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6",
                      zIndex: 999,
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h3">Client / Customer Management</CardTitle>
                      <ul>
                        <li>
                          In Depth Family Profiles
                        </li>
                        <li>
                          Waivers
                        </li>
                        <li>
                          Tags
                        </li>
                        <li>
                          Recurring Memberships
                        </li>
                        <li>
                          Class Packs
                        </li>
                        {!showMore && <li style={{
                          cursor: "pointer",
                          textDecoration: "underline"
                        }} onClick={() => {
                          setShowMore(!showMore);
                        }}>
                          And More...
                        </li>}
                        {showMore && <>

                          <li>
                            Single Day Passes
                          </li>
                          <li>
                            Client Tracking
                          </li>
                          <li>
                            Client History
                          </li>
                          <li>
                            Notes
                          </li>
                          <li>
                            Branded Client Portal to Self Manage Memberships and Schedule
                          </li>
                          <li>
                            Rebook / Cancel
                          </li>
                          <li>
                            Class Wait Lists
                          </li>
                          <li>
                            Full Site Search Bar
                          </li>
                        </>}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6",
                      zIndex: 999,
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h3">Calendar</CardTitle>
                      <ul>
                        <li>
                          Website Integrated Events Calendar
                        </li>
                        <li>
                          Mobile Friendly
                        </li>
                        <li>
                          Search by Time, Age, Activity, Instructor
                        </li>
                        <li>
                          Private Event Bookings and Add Ons
                        </li>
                        <li>
                          Party Add Ons
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6",
                      zIndex: 999,
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h3">Reports</CardTitle>
                      <ul>
                        <li>
                          Revenue
                        </li>
                        <li>
                          Sort Revenue by Activity, Instructor, Age, Income stream
                        </li>
                        <li>
                          Client Insights
                        </li>
                        <li>
                          Referrals
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6",
                      zIndex: 999,
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h3">Lead Management and Retention</CardTitle>
                      <ul>
                        <li>
                          Contact and Retain New Customers
                        </li>
                        <li>
                          Email list integration
                        </li>
                        <li>
                          Smart Contact List (by age, tags, previous classes)
                        </li>
                        <li>
                          Custom Communication Templates
                        </li>
                        <li>
                          Automatic Notifications for Client and Business Owner
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6",
                      zIndex: 999,
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h3">Staff Management</CardTitle>
                      <ul>
                        <li>
                          Time Clock
                        </li>
                        <li>
                          Staff Profiles
                        </li>
                        <li>
                          Payroll Reports
                        </li>
                        <li>
                          To Do Lists
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6",
                      zIndex: 999,
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h3">Inventory</CardTitle>
                      <ul>
                        <li>
                          Sell Products In Store & Online
                        </li>
                        <li>
                          Inventory Categories
                        </li>
                        <li>
                          Customize Coupons, Discounts, and Promotions
                        </li>
                        <li>
                          Stripe Integration
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="pt-5" md="3">
                  <Card
                    className="card-pricing card-background"
                    style={{
                      background: "#d5dcf6Z",
                      zIndex: 999,
                    }}
                  >
                    <CardBody style={{
                      background: "transparent !important"
                    }}>
                      <CardTitle tag="h3">Pricing</CardTitle>
                      <ul>
                        <li>
                          <i className="now-ui-icons ui-1_check text-success"></i>{" "}<b>$239</b> Monthly (limited
                          time)
                        </li>
                        <li>
                          <i className="now-ui-icons ui-1_check text-success"></i>{" "}<b>All</b> Features Included
                        </li>
                        <li>
                          <i className="now-ui-icons ui-1_check text-success"></i>{" "}<b>Simple</b> Signup
                        </li>
                        <li>
                          <i className="now-ui-icons ui-1_check text-success"></i>{" "}<b>No</b> Cancellation Fees
                        </li>
                        <li>
                          <i className="now-ui-icons ui-1_check text-success"></i>{" "}
                          Premium Support Included
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg="5" md="12">
              <div className="github-background-container">
                <i className="fab fa-github"></i>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FreeDemo;
