/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <div className="copyright" id="copyright">
            &copy; {new Date().getFullYear()},&nbsp;
            <a
              href="https://playspacebrain.com"
              target="_blank"
            >
              Play Space Brain
            </a>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
