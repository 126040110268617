import React, {useEffect, useRef} from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import PresentationHeader from "components/Headers/PresentationHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

// sections for this page
import BasicComponents from "./presentation-sections/BasicComponents.js";
import Content from "./presentation-sections/Content.js";
import Sections from "./presentation-sections/Sections.js";
import Examples from "./presentation-sections/Examples.js";
import FreeDemo from "./presentation-sections/FreeDemo.js";
import Pricing from "./presentation-sections/Pricing.js";
import {useLocation} from "react-router-dom";
import CalendarComponent from "./presentation-sections/CalendarComponent";

function Presentation() {

  const myRef = useRef();

  const location = useLocation();

  useEffect(() => {
    scrollToComponent();
  }, [location]);



  function scrollToComponent() {
    if (window.location.hash) {
      var hash = window.location.hash;
      var el = document.getElementById(hash.replace('#', ''));
      if (!el) {
        return;
      }
      var offset = el.offsetTop;
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");

    // initialise Rellax for this page
    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <PresentationHeader />
        {/*<Components />*/}
        <BasicComponents />
        {/*<Cards />*/}
        <Sections />
        <Examples />
        <CalendarComponent />
        <Content />
        <FreeDemo />
        <Examples />
        {/*<Icons />*/}
        {/*<Image />*/}
        {/*<Testimonials />*/}
        <Pricing />
        <FooterBlack />
      </div>
    </>
  );
}

export default Presentation;
