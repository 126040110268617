import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function Examples() {
  return (
    <>
      <div className="section section-examples" data-background-color="black" id="features">
        <Container>
        </Container>
      </div>
    </>
  );
}

export default Examples;
