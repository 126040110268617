/*eslint-disable*/
import React from "react";

// reactstrap components

// core components

function PresentationHeader() {
  return (
    <>
      <div className=" clear-filter" style={{
        background: "#000",
        position: "relative",
        paddingTop: "100px",
        height: "auto",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" +
          require("images/bubble-time.png") +
          ")",
        borderBottom: "solid 10px #FFCC00",
      }}>
        <div className="rellax-text-container rellax-text" style={{zIndex: 9}}>
          <h1 className="h1-seo" data-rellax-speed="-1" style={{
            textAlign: "left",
            letterSpacing: "0 !important",
            fontSize: "10vw",
          }}>
            Complete<br/>
            Play Space<br/>
            Management<br/>
            Solution<br/>
          </h1>

          <h3
            style={{
              clear: "both",
              position: "relative",
              bottom: "20px",
              marginLeft: "20px"
            }}
            className="h3 rellax-text text-white text-left" data-rellax-speed="-1">
            All in one software solution for indoor play spaces
          </h3>
        </div>

      </div>
    </>
  );
}

export default PresentationHeader;
