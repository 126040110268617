import React from "react";
import {Link} from "react-router-dom";
// reactstrap components
import {Button, Container, Row, Col} from "reactstrap";

// core components

function Sections() {
  return (
    <>
      <div className="section section-sections" data-background-color="gray" id="tools">
        <Container>
          <Col className="ml-auto mr-auto" md="8">
            <div className="section-description text-center">
              <h2 className="title">A Single Place For All Of Your Daily Operations</h2>
              <h5 className="description" style={{color: '#111'}}>
                Manage your customers, create recurring events, manage inventory, add waivers, detailed reporting, and
                much more...
              </h5>
              {/*<Button*/}
              {/*  className="btn-round"*/}
              {/*  to="/sections"*/}
              {/*  color="info"*/}
              {/*  tag={Link}*/}
              {/*>*/}
              {/*  View All Sections*/}
              {/*</Button>*/}
            </div>
          </Col>
        </Container>

        <div className="section-description text-center">
          <h2 className="title">
            Selected Sample Pages
          </h2>
        </div>

        <Container fluid>
          <div className="section-cols">
            <Row>

              <Col md="3">
                <h5 className={'text-center'}>Company Customization</h5>
                <img
                  alt="Company Customization"
                  src={require("images/playspace-client-manage-company.png")}
                ></img>
              </Col>

              <Col md="3">
                <h5 className={'text-center'}>Manage Customers</h5>
                <img
                  alt="List Customers"
                  src={require("images/playspace-list-customers.png")}
                ></img>
              </Col>

              <Col md="3">
                <h5 className={'text-center'}>Waiver Manager</h5>
                <img
                  alt="Customizable Waivers"
                  src={require("images/playspace-client-manage-waiver.png")}
                ></img>
              </Col>

              <Col md="3">
                <h5 className={'text-center'}>Create Memberships</h5>
                <img
                  alt="Membership management"
                  src={require("images/playspace-memberships.png")}
                ></img>
              </Col>

              <Col md="3">
                <h5 className={'text-center'}>Calendar Events</h5>
                <img
                  alt="Membership management"
                  src={require("images/playspace-client-view-events.png")}
                ></img>
              </Col>

              <Col md="3">
                <h5 className={'text-center'}>Smart Search</h5>
                <img
                  alt="Smart Search"
                  src={require("images/playspace-smart-search.png")}
                ></img>
              </Col>

              <Col md="3">
                <h5 className={'text-center'}>Manage Employees</h5>
                <img
                  alt="Employee management"
                  src={require("images/playspace-manage-employee.png")}
                ></img>
              </Col>

              <Col md="3">
                <h5 className={'text-center'}>Manage Inventory</h5>
                <img
                  alt="Employee management"
                  src={require("images/playspace-manage-inentory.png")}
                ></img>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Sections;
