import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Content() {
  return (
    <>
      <div className="section section-content" data-background-color="black" id="reports">
        <Container>
          <Row>
            <Col md="5">
              <div className="image-container">
                <img
                  alt="..."
                  className="img img-raised rounded img-comments rellax"
                  data-rellax-speed="1"
                  src={require("images/reports-staging.png")}
                ></img>
                <img
                  alt="..."
                  className="img img-raised rounded img-blog rellax"
                  data-rellax-speed="4"
                  src={require("images/reports-staging-2.png")}
                ></img>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="4">
              <div className="section-description">
                <h2 className="title">Reports</h2>
                <h6 className="category">Transform your data into valuable insights that drive better decision-making</h6>
                <h5 className="description" style={{
                  fontWeight:'normal'
                }}>
                  Play Space Brain's reporting tools provide you with the data you need to make informed decisions.
                  Our reporting tools are designed to help you understand your business, customers, and sales.
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Content;
