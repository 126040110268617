/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components

function Pricing() {
  return (
    <>
      <div className="section section-pricing" id="contact">
        <Container>

          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Ready to create an account?</h2>
            </Col>
            <Col className="text-center ml-auto mr-auto" md="8" style={{
              maxWidth: '100%',
              overflow: 'auto'
            }}>
              {/*<Button*/}
              {/*  color="info"*/}
              {/*  target="_blank"*/}
              {/*  href="mailto:monica@playspacebrain.com"*/}
              {/*>*/}
              {/*  Contact Us*/}
              {/*</Button>*/}

              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSeTZ7laByRmyqDwRFCGB2OPRhK9uWeiLg50JF69e512-5BSTQ/viewform?embedded=true"
                width="640" height="1050" frameBorder="0" marginHeight="0" marginWidth="0">Loading...
              </iframe>
            </Col>
            <Col className="text-center ml-auto mr-auto mt-5" md="8">
              <h3>Thank you for supporting us!</h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Pricing;
