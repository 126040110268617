import React, {useEffect, useRef} from "react";

// reactstrap components
import {Container, Row, Col, Button} from "reactstrap";
import {useLocation} from "react-router-dom";

// core components

function BasicComponents() {

  return (
    <>
      <div className="section section-basic-components" id="manage-components">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <h2 className="title">Manage Your Play Space</h2>
              <h6 className="category">Simple Play Space Software</h6>
              <h5 className="description" style={{
                color: "#111"
              }}>
                Centralize your daily operations with Play Space Brain's robust management tools,
                tailored specifically for indoor play spaces.
                <hr />
                Our software was developed for Play Space owners by Play Space owners.
                Manage your customers, events, parties, store, staff, and more with ease.
              </h5>
              <Button className={'btn-round'} color="info" href="#contact">
                Contact Us
              </Button>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="components-macbook"
                  src={require("images/ipad-1.png")}
                ></img>
                <img
                  alt="..."
                  className="table-img"
                  src={require("images/play-space-storefront.png")}
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={require("images/play-space-customer-cart.png")}
                ></img>
                {/*<img*/}
                {/*  alt="..."*/}
                {/*  className="coloured-card-btn-img"*/}
                {/*  src={require("assets/img/presentation-page/coloured-card-with-btn.jpg")}*/}
                {/*></img>*/}
                {/*<img*/}
                {/*  alt="..."*/}
                {/*  className="coloured-card-img"*/}
                {/*  src={require("assets/img/presentation-page/coloured-card.jpg")}*/}
                {/*></img>*/}
                {/*<img*/}
                {/*  alt="..."*/}
                {/*  className="social-img"*/}
                {/*  src={require("assets/img/presentation-page/social-row.jpg")}*/}
                {/*></img>*/}
                {/*<img*/}
                {/*  alt="..."*/}
                {/*  className="linkedin-btn-img"*/}
                {/*  src={require("assets/img/presentation-page/linkedin-btn.jpg")}*/}
                {/*></img>*/}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default BasicComponents;
